import './pages.css'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FooterFixed from './FooterFixed';

async function getCourts() {
  let courtsList
  try {
    const response = await fetch('https://24sljl6d8d.execute-api.us-east-1.amazonaws.com/items', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
      }
    })

    courtsList = await response.json();
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.log('There was a SyntaxError', error);
    } else {
      console.log('There was an error fetching the courts list', error);
    }
  }

  if (courtsList) {
    return courtsList;
  }

}

function RenderTable() {
  const [courts, setCourts] = useState([]);
  const [courtsList, setCourtsList] = useState([]);

  function filterNames() {
    let currentFilter = document.getElementById("namesChange").value
    let courtsListCopy = courtsList
    let filtered = courtsListCopy.filter(function (el) {
      return el.name.toLowerCase().includes(currentFilter.toLowerCase())
    })
    setAllCourtsFilter(filtered)
  }

  function setAllCourtsFilter(listOfCourts) {
    let items = []
    for (const curCourt of listOfCourts) {
      items.push(<tr key={curCourt.id}>
        <td> {curCourt.name} </td>
        <td> {curCourt.courtType} </td>
        <td> <Link to={`https://maps.google.com/?q=${curCourt.latitude},${curCourt.longitude}`} target="_blank">View Court on Google Maps</Link></td>
      </tr>)
      setCourts(items)
    }
  }

  function setAllCourts(listOfCourts) {
    listOfCourts = listOfCourts.filter(function (el) {
      return !el.name.toLowerCase().includes("pending")
    })
    let items = []
    setCourtsList(listOfCourts)
    for (const curCourt of listOfCourts) {
      items.push(<tr key={curCourt.id}>
        <td> {curCourt.name} </td>
        <td> {curCourt.courtType} </td>
        <td> <Link to={`https://maps.google.com/?q=${curCourt.latitude},${curCourt.longitude}`} target="_blank">View Court on Google Maps</Link></td>
      </tr>)
      setCourts(items)
    }
  }

  useEffect(() => {
    getCourts().then((courts) =>
      setAllCourts(courts))
  }, [])

  return <div id="Table">
    <input type="text" placeholder="Search for a court near you" id="namesChange" onChange={filterNames}></input>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Court Type</th>
          <th>Google Maps Link</th>
        </tr>
      </thead>
      <tbody>
        {courts}
      </tbody>
    </table></div>
}

const CourtsListView = () => {
  return (
    <div>
      <div className={"content"}>
        <h3>Approved Courts List View</h3>
        <RenderTable />
      </div>
      <FooterFixed />
    </div>
  );
}
export default CourtsListView;