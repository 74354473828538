import './pages.css'
import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import APIKEY from '../components/constants'

async function getCourts() {
  let courtsList
  try {
    const response = await fetch('https://24sljl6d8d.execute-api.us-east-1.amazonaws.com/items', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
      }
    })

    courtsList = await response.json();
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.log('There was a SyntaxError', error);
    } else {
      console.log('There was an error fetching the courts list', error);
    }
  }

  if (courtsList) {
    return courtsList;
  }

}

function Map() {
  function returnAddCourtButtonToDefaultText() {
    setTimeout(function () {
      setPotentialCourt("Add Court")
    }, 5000);
  }

  async function addCourt() {
    let curAddressReturn = document.getElementById("addressReturn").value;
    let curLocationType = document.getElementById("locationType").value;
    let curIsIndoor = document.getElementById("isIndoor").checked;
    let curIsPublic = document.getElementById("isPublic").checked;
    let curNotes = document.getElementById("notes").value;

    if (curAddressReturn === "") {
      setPotentialCourt("Plese enter a valid address")
      returnAddCourtButtonToDefaultText();
      return;
    }

    if (curLocationType === "Empty") {
      setPotentialCourt("Plese enter a valid court type")
      returnAddCourtButtonToDefaultText();
      return;
    }

    let latLongConversion = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(curAddressReturn) + "&key=" + APIKEY
    let googleResponse
    try {
      const response = await fetch(latLongConversion, {
        method: 'GET',
      });
      googleResponse = await response.json();
    } catch (error) {
      console.log('There was an error converting your court please try again', error);
    }

    try {
      await fetch('https://24sljl6d8d.execute-api.us-east-1.amazonaws.com/items', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
        },
        body: JSON.stringify({
          'id': Date(),
          'name': 'Pending',
          'courtClosestAddress': curAddressReturn,
          'latitude': googleResponse.results[0].geometry.location.lat,
          'longitude': googleResponse.results[0].geometry.location.lng,
          'publicFlag': curIsPublic,
          'indoorFlag': curIsIndoor,
          'courtType': curLocationType,
          'courtNotes': curNotes,
          'isPending': true
        })
      });

      document.getElementById("addressReturn").value = "";
      document.getElementById("locationType").value = "";
      document.getElementById("notes").value = "";

      setPotentialCourt("Thanks for adding your court!")
      returnAddCourtButtonToDefaultText();

    } catch (error) {
      setPotentialCourt("There was an issue adding your court!")
      returnAddCourtButtonToDefaultText();
      if (error instanceof SyntaxError) {
        console.log('There was a SyntaxError', error);
      } else {
        console.log('There was an error fetching the courts list', error);
      }
    }
  }

  const [position, setPosition] = useState({ lat: 38.9101700941462, lng: -76.98810861426665 });
  const [potentialCourt, setPotentialCourt] = useState(["Add Court"]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  let map;

  async function initMap() {
    // The location of Uluru

    // Request needed libraries.
    //@ts-ignore
    // eslint-disable-next-line
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    // eslint-disable-next-line
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    map = new Map(document.getElementById("map"), {
      zoom: 12,
      center: position,
      mapId: "COURT_MAP_ID"
    });

    const infoWindow = new InfoWindow();

    var locations = await getCourts();

    // eslint-disable-next-line
    for (let i = 0; i < locations.length; i++) {
      let icon = document.createElement("img");

      if (locations[i].courtType === "Basketball") {
        if (!locations[i].isPending) {
          if (locations[i].indoorFlag) {
            icon.src = "basketball indoor.png";
          } else {
            icon.src = "basketball outdoor.png";
          }
        } else {
          if (locations[i].indoorFlag) {
            icon.src = "basketball indoor pending.png";
          } else {
            icon.src = "basketball outdoor pending.png";
          }
        }
      } else if (locations[i].courtType === "Tennis") {
        if (!locations[i].isPending) {
          if (locations[i].indoorFlag) {
            icon.src = "tennis indoor.png";
          } else {
            icon.src = "tennis outdoor.png";
          }
        } else {
          if (locations[i].indoorFlag) {
            icon.src = "tennis indoor pending.png";
          } else {
            icon.src = "tennis outdoor pending.png";
          }
        }
      } else if (locations[i].courtType === "Golf") {
        if (!locations[i].isPending) {
          icon.src = "golf.png";
        } else {
          icon.src = "golf pending.png";
        }

      } else if (locations[i].courtType === "Track") {
        if (!locations[i].isPending) {
          if (locations[i].indoorFlag) {
            icon.src = "track indoor.png";
          } else {
            icon.src = "track outdoor.png";
          }
        } else {
          if (locations[i].indoorFlag) {
            icon.src = "track indoor pending.png";
          } else {
            icon.src = "track outdoor pending.png";
          }
        }
      }
      let curPublicFlag = (locations[i].publicFlag) ? "public" : "private"
      let curIndoorFlag = (locations[i].indoorFlag) ? "indoor" : "outdoor"

      const marker = new AdvancedMarkerElement({
        // eslint-disable-next-line
        position: { lat: locations[i].latitude, lng: locations[i].longitude },
        map: map,
        content: icon,
        title: locations[i].name + curPublicFlag,
        gmpClickable: true,
        // eslint-disable-next-line
        collisionBehavior: google.maps.CollisionBehavior.REQUIRED
      });
      let contentString = "";

      if (locations[i].name === "Pending") {
        contentString = `This is a pending <b>${locations[i].courtType.toLowerCase()}</b> facility. Please give us a couple of days to review your addition.`
      } else {
        contentString =
          `<div id="content">
        <div id="siteNotice">
        </div>
        <h1 id="firstHeading" class="firstHeading">${locations[i].name}</h1>
        <div id="bodyContent"><p><b><a href="https://maps.google.com/?q=${locations[i].latitude},${locations[i].longitude}">
        ${locations[i].name}</a> </b>, is a ${curPublicFlag}, ${curIndoorFlag} ${locations[i].courtType.toLowerCase()} facility.</p></div></div>`
      }


      marker.addListener("click", ({ domEvent, latLng }) => {

        infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(marker.map, marker);
      });

    }

    if (position.lat !== 38.9101700941462 && position.lng !== -76.98810861426665) {
      let icon = document.createElement("img");
      icon.src = "youarehere.png"
      new AdvancedMarkerElement({
        // eslint-disable-next-line
        position: position,
        map: map,
        content: icon,
        gmpClickable: true,
        // eslint-disable-next-line
        collisionBehavior: google.maps.CollisionBehavior.REQUIRED
      })
    }

    // eslint-disable-next-line
    const { Place } = await google.maps.importLibrary("places");

    const addressReturnInput = document.getElementById("addressReturn");
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };
    // eslint-disable-next-line
    new google.maps.places.Autocomplete(addressReturnInput, options);
    // eslint-disable-next-line
  }

  initMap();

  return (
    <div className="Map">
      <div id="map"></div>

      <div className={"content"}>
        {/* <div style={{ float: 'right', fontSize: "11px" }}><img src={"basketball outdoor pending.png"} width={30} height={30} alt="Pending Outdoor" />Pending Outdoor&nbsp;<img src={"basketball indoor pending.png"} width={30} height={30} alt="Pending Indoor" />Pending Indoor</div>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"basketball outdoor.png"} width={30} height={30} alt="Outdoor" />Outdoor&nbsp;<img src={"basketball indoor.png"} width={30} height={30} alt="Public Indoor" />Indoor&nbsp;</div>
        &nbsp;
        <p></p>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"tennis outdoor pending.png"} width={30} height={30} alt="Tennis Outdoor" />Pending Outdoor&nbsp;<img src={"tennis indoor pending.png"} width={30} height={30} alt="Pending Indoor" />Pending Indoor</div>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"tennis outdoor.png"} width={30} height={30} alt="Tennis Outdoor" />Outdoor&nbsp;<img src={"tennis indoor.png"} width={30} height={30} alt="Public Indoor" />Indoor&nbsp;</div>
        &nbsp;
        <p></p>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"track outdoor pending.png"} width={26} height={26} alt="Track Outdoor" /> Pending Outdoor&nbsp;<img src={"track indoor pending.png"} width={26} height={26} alt="Pending Indoor" /> Pending Indoor</div>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"track outdoor.png"} width={26} height={26} alt="Track Outdoor" /> Outdoor&nbsp;<img src={"track indoor.png"} width={26} height={26} alt="Public Indoor" /> Indoor&nbsp;</div>
        &nbsp;
        <p></p>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"golf pending.png"} width={30} height={30} alt="Golf Pending Outdoor" />Pending Outdoor&nbsp;</div>
        <div style={{ float: 'right', fontSize: "11px" }}><img src={"golf.png"} width={30} height={30} alt="Golf Outdoor" />Outdoor&nbsp;</div> */}

        <h1>Want to add your court?</h1>

        <p>What is the closest address to your court:</p>
        <input id="addressReturn" type="text" placeholder="Enter a location" />

        <p>What kind of court would you like to add?</p>
        <select name="locationType" id="locationType">
          <option value="Empty"></option>
          <option value="Basketball">Basketball</option>
          <option value="Tennis">Tennis</option>
          <option value="Track">Track</option>
          <option value="Golf">Golf</option>
        </select>
        <br></br>
        <br></br>

        <div className='inputText'>
          Outdoor
          <label className="switch">
            <input id="isIndoor" type="checkbox" />
            <span className="slider"></span>
          </label>
          Indoor
          &nbsp;
          &nbsp;
          Private
          <label className="switch">
            <input id="isPublic" type="checkbox" />
            <span className="slider"></span>
          </label>
          Public
        </div>

        <p>Any other notes about your court:</p>
        <input type="text" id="notes" ></input>

        <br></br>
        <br></br>

        <button onClick={addCourt}>{potentialCourt}</button>
      </div>
      <Footer />
    </div>
  );
}

export default Map;

// potentially use for reverse geo coding https://developers.google.com/maps/documentation/geocoding/requests-geocoding#component-filtering