import './pages.css'
import React from 'react';
import { Link } from 'react-router-dom';

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  position: "relative",
  bottom: "0",
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  fontFamily: "arial, sans-serif"
}

function Footer() {
  return (
    <div style={phantom}>
      <div style={{ float: "right" }}>
        <Link to={`https://github.com/tylerdamonwill`} target="_blank"><img src={"github.png"} width={30} height={30} alt="Github" /></Link>
        &nbsp;
        <Link to={`https://www.linkedin.com/in/tyler-williams-35519611b/`} target="_blank"><img src={"linkedin.png"} width={30} height={30} alt="LinkedIn" /></Link>
        &nbsp;
      </div>
      <div style={{ float: "left" }}>
        &copy;TylerMadeSoftwareSolutions
      </div>
    </div>
  )
}
export default Footer;