import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'

function closeMobileHeader() {
    var x = document.getElementById("myTopnav");
     if (x.className === "navBar") {
      x.className += " responsive";
      x = document.getElementById("navHamburger");
      x.className = "fa fa-close"
    } else {
      x.className = "navBar";
      x = document.getElementById("navHamburger");
      x.className = "fa fa-bars"
     }
  }

const Navbar = () => {
    return (
        <div className={'navBar'} id={"myTopnav"}>
            <Link onClick={closeMobileHeader} to="/">Home</Link>

            <Link onClick={closeMobileHeader} to="/courts-list-view">Court List View</Link>

            {/* <Link onClick={closeMobileHeader} to="/about-the-app">About the App</Link> */}

            {/* <Link onClick={closeMobileHeader} to="/contact">Contact</Link> */}
            <a className={"icon"} onClick={closeMobileHeader}>
                <i id={"navHamburger"} className={"fa fa-bars"}></i>
            </a>
        </div>
    )

}

export default Navbar;