import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar"
import Map from "./pages/Map";
import CourtsListView from "./pages/CourtsListView"

function App() {
  function hideMap() {
    document.getElementById("map").style.display = 'block';
}
  return (
    <>
    <Navbar/>
      <div>
    <Routes>   
      <Route path='/' onClick={hideMap} element={<Map/>} />
      <Route path='/courts-list-view' onClick={hideMap} element={<CourtsListView/>} />
      {/* <Route path='/about-the-app' onClick={hideMap} element={<AboutTheApp/>} /> */}
      {/* <Route path='/contact' onClick={hideMap} element={<Contact/>} /> */}
      </Routes>
    </div>
    </>
  );
}

export default App;
